.Info-Box {
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-evenly;
  margin: auto;
  padding-top: 4%;
  text-align: left;
}

.SeeCode-Btn {
  background-color: #8cc9f0;
  color: white;
  border: 0;
  padding: 0% 5%;
  margin: 7% 0% 0% 0%;
  font-size: 2.2rem;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  height: 2.75em;
  line-height: 2.75em;
}

.Center-underline:after {
  margin-left: auto;
  margin-right: auto;
}

.Contact-Box {
  color: white;
}

@media (max-width: 999px) {
  .Info-Box {
    display: flex;
    flex-direction: row;
    width: 80%;
  }
}

@media (max-width: 600px) {
  h3 {
    font-size: 2rem;
  }

  .Info-Box {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .SeeCode-Btn {
    width: 80%;
  }
}
