html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* ---------------------- CSS Reset ^  ------------------ */

*,
html {
  box-sizing: border-box;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  line-height: 1.65;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("images/overlay.png"),
    -moz-linear-gradient(45deg, #000000 15%, #acdf9a 85%);
  background-image: url("images/overlay.png"),
    -webkit-linear-gradient(45deg, #000000 15%, #acdf9a 85%);
  background-image: url("images/overlay.png"),
    -ms-linear-gradient(45deg, #000000 15%, #acdf9a 85%);
  background-image: url("images/overlay.png"),
    linear-gradient(45deg, #000000 15%, #acdf9a 85%);
}

/* 
 #acdf9a
#42a871
#c89b9d */

a {
  -moz-transition: color 0.2s ease, border-bottom 0.2s ease;
  -webkit-transition: color 0.2s ease, border-bottom 0.2s ease;
  -ms-transition: color 0.2s ease, border-bottom 0.2s ease;
  transition: color 0.2s ease, border-bottom 0.2s ease;
  text-decoration: none;

  color: inherit;
}

a:hover {
  /* border-bottom-color: transparent; */
  border-bottom: dotted 1px;
}

strong,
b {
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 0.7em 0;
  letter-spacing: -0.025em;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 5.5rem;
  line-height: 1.2;
}

h2 {
  font-size: 4.5rem;
  font-weight: 400;
}

h2:after {
  display: block;
  content: "";
  width: 3.25em;
  height: 2px;
  margin: 0.7em 0 1em 0;
  border-radius: 2px;
  background-color: #dddddd;
  background-image: -moz-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
  background-image: -webkit-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
  background-image: -ms-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
  background-image: linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
}

h3 {
  font-size: 2.7rem;
}

p {
  text-align: justify;
}

p,
dt,
dd, 
dd a,
.AboutMe-Content a {
  font-size: 1.86rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}