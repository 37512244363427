.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Section {
  padding: 5% 0%;
  width: 90%;
  border-bottom: 1px solid #dddddd;
}

.Content-container {
  min-height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 74%;
  color: #636363;
  border-radius: 0.5rem;
}

nav a {
  font-size: 1.8rem;
}

dt {
  font-weight: 400;
  align-self: flex-start;
}
